<template>
    <div v-loading.fullscreen.lock="loading">
        <budget-use-search-bar-component
            :AllType="AllType"
            :AllEnterprise="AllEnterprise"
            :AllYear="AllYear"
            :AllStaff="AllStaff"
            @search="get_budget_use_index"
            @out_excel="outExcel"
        ></budget-use-search-bar-component>
        <common-table-component
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            button_name="查看"
            @watch_row="watch_row"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_budget_use_index"
        ></common-page-component>

        <purchase-edit-component
            :id="purchase_component.id"
            :dialogFormVisible="purchase_component.dialogFormVisible"
            :purchase_details_data="purchase_component.purchase_details_data"
            @exitPurchaseDialog="purchase_component.dialogFormVisible = false"
        ></purchase-edit-component>
        <entertain-edit-component
            :id="entertain_component.id"
            :dialogFormVisible="entertain_component.dialogFormVisible"
            :entertain_details_data="entertain_component.entertain_details_data"
            @exitEntertainDialog="entertain_component.dialogFormVisible = false"
        ></entertain-edit-component>
        <travel-edit-component
            :id="travel_component.id"
            :dialogFormVisible="travel_component.dialogFormVisible"
            :travel_details_data="travel_component.travel_details_data"
            @exitTravelDialog="travel_component.dialogFormVisible = false"
        ></travel-edit-component>
        <reimbursement-edit-component
            :id="reimbursement_component.id"
            :dialogFormVisible="reimbursement_component.dialogFormVisible"
            :reimbursement_details_data="reimbursement_component.reimbursement_details_data"
            @exitReimbursementDialog="reimbursement_component.dialogFormVisible = false"
        ></reimbursement-edit-component>
        <entertain-use-edit-component
            :id="entertain_use_component.id"
            :dialogFormVisible="entertain_use_component.dialogFormVisible"
            :entertain_use_details_data="entertain_use_component.entertain_use_details_data"
            :AllStaff="AllStaff"
            :enterprise_department="enterprise_department"
            @exitEntertainUseDialog="entertain_use_component.dialogFormVisible = false"
        ></entertain-use-edit-component>
        <!-- 导出 -->
        <common-out-put-excel-component
            ref="out_excel"
            :TableName="out_excel_name"
            :HeaderArr="out_excel_heard"
            :BobyArr="out_excel_data"
        ></common-out-put-excel-component>
    </div>
</template>

<script>
import { budget_use_index_request,out_excel_request } from '@/network/finance/BudgetUse.js'
import { enterprise_list_request,staff_list_request } from '@/network/list.js'
import { enterprise_department_request} from '@/network/enterprise.js'
import { thousandBitSeparator,fomatFloat} from '@/assets/js/common.js'

import BudgetUseSearchBarComponent from '@/components/finance/BudgetUse/BudgetUseSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'

import { purchase_details_request} from '@/network/finance/purchase.js'
import PurchaseEditComponent from '@/components/finance/purchase/PurchaseEditComponent'

import { entertain_details_request} from '@/network/finance/entertain.js'
import EntertainEditComponent from '@/components/finance/entertain/EntertainEditComponent'

import { travel_details_request} from '@/network/finance/travel.js'
import TravelEditComponent from '@/components/finance/travel/TravelEditComponent'

import { reimbursement_details_request} from '@/network/finance/reimbursement.js'
import ReimbursementEditComponent from '@/components/finance/reimbursement/ReimbursementEditComponent'

import { entertain_use_details_request} from "@/network/finance/EntertainUse.js";
import EntertainUseEditComponent from "@/components/finance/EntertainUse/EntertainUseEditComponent";

import CommonOutPutExcelComponent from '@/components/common/CommonOutPutExcelComponent'

export default {
    name: 'BudgetUseView',
    data() {
        return {
            fid: 0,
            loading: false,
            ftotal: 0,
            cond: {
                type: '',
                year: '',
                quarter: '',
                enterprise_id: '',
                department_id: '',
                staff_id: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'create_time',
                    label: '日期',
                    minWidth: '100px'
                },
                {
                    prop: 'type_name',
                    label: '费用类型',
                    minWidth: '100px',
                    html:(row) => {
                        if(row['type'] === 0) {
                            return `<div class="cell"><span class="el-tag el-tag el-tag--plain">招待类</span></div>`
                        } else if(row['type'] === 1) {
                            return `<div class="cell"><span class="el-tag el-tag el-tag--plain el-tag--warning">差旅类</span></div>`
                        } else if(row['type'] === 2) {
                            return `<div class="cell"><span class="el-tag el-tag el-tag--plain el-tag--success">其它类</span></div>`
                        }
                    }
                },
                {
                    prop: 'money',
                    label: '金额(元)',
                    minWidth: '120px',
                    html:(row) => {
                        return '<div style="text-align: right;">' + thousandBitSeparator(fomatFloat(row['money'])) + '</div>'
                    }
                },
                {
                    prop: 'enterprise_name',
                    label: '企业',
                    minWidth: '260px'
                },
                {
                    prop: 'department_name',
                    label: '部门',
                    minWidth: '150px'
                },
                {
                    prop: 'create_staff_name',
                    label: '使用人',
                    minWidth: '100px'
                },
                {
                    prop: 'from_type_name',
                    label: '来源单据',
                    minWidth: '100px'
                },
                {
                    prop: 'with_from_name',
                    label: '单号',
                    minWidth: '120px'
                }
            ],
            AllType: ['招待','差旅','其它'],
            AllYear: [{id:2023,name:'2023年'},{id:2024,name:'2024年'},{id:2025,name:'2025年'},{id:2026,name:'2026年'},{id:2027,name:'2027年'},{id:2028,name:'2028年'},{id:2029,name:'2029年'},{id:2030,name:'2030年'}],
            AllEnterprise: [],
            AllStaff: [],
            enterprise_department: [],
            purchase_component: {
                id: 0,
                dialogFormVisible: false,
                purchase_details_data: {}
            },
            entertain_component: {
                id: 0,
                dialogFormVisible: false,
                entertain_details_data: {}
            },
            travel_component: {
                id: 0,
                dialogFormVisible: false,
                travel_details_data: {}
            },
            reimbursement_component: {
                id: 0,
                dialogFormVisible: false,
                reimbursement_details_data: {}
            },
            entertain_use_component: {
                id: 0,
                dialogFormVisible: false,
                entertain_use_details_data: {}
            },
            out_excel_heard: [],
            out_excel_data: []
        }
    },
    computed: {
        out_excel_name() {
            const n = new Date();
            const year = n.getFullYear();
            const month = (((n.getMonth()) + 1)+'').length === 1 ? ('0' + ((n.getMonth()) + 1)) : ((n.getMonth()) + 1);
            const date = (n.getDate()+'').length === 1 ? ('0' + n.getDate()) : n.getDate();
            const hour = (n.getHours()+'').length === 1 ? ('0' + n.getHours()) : n.getHours();
            const minute = (n.getMinutes()+'').length === 1 ? ('0' + n.getMinutes()) : n.getMinutes();
            const second = (n.getSeconds()+'').length === 1 ? ('0' + n.getSeconds()) : n.getSeconds();

            return '预算使用明细' + year +  month + date +  hour +  minute + second
        }
    },
    methods: {
        get_budget_use_index(param = {}) {
            this.loading = true
            this.cond.type = param.type ?? this.cond.type
            this.cond.year = param.year ?? this.cond.year
            this.cond.quarter = param.quarter ?? this.cond.quarter
            this.cond.enterprise_id = param.enterprise_id ?? this.cond.enterprise_id
            this.cond.department_id = param.department_id ?? this.cond.department_id
            this.cond.staff_id = param.staff_id ?? this.cond.staff_id
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            budget_use_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_enterprise() {
            this.loading = true
            enterprise_list_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.AllEnterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_staff() {
            this.loading = true
            staff_list_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.AllStaff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_enterprise_department() {
            this.loading = true
            enterprise_department_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.enterprise_department = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        watch_row(row) {
            if(row.from_type === 0) {   //报销
                this.reimbursement_component.id = row.with_from_id
                this.reimbursement_component.dialogFormVisible = true
                reimbursement_details_request(row.with_from_id)
                .then((s) => {
                    if (s.status === 0) {
                        this.reimbursement_component.reimbursement_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            } else if(row.from_type === 1) {    //采购
                this.purchase_component.id = row.with_from_id
                this.purchase_component.dialogFormVisible = true
                purchase_details_request(row.with_from_id)
                .then((s) => {
                    if (s.status === 0) {
                        this.purchase_component.purchase_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            } else if(row.from_type === 2) {    //招待
                this.entertain_component.id = row.with_from_id
                this.entertain_component.dialogFormVisible = true
                entertain_details_request(row.with_from_id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.entertain_component.entertain_details_data = s.result
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(row.from_type === 3) {    //差旅
                this.travel_component.id = row.with_from_id
                this.travel_component.dialogFormVisible = true
                travel_details_request(row.with_from_id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.travel_component.travel_details_data = s.result
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(row.from_type === 4) {    //领用
                this.entertain_use_component.id = row.with_from_id
                this.entertain_use_component.dialogFormVisible = true
                entertain_use_details_request(row.with_from_id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.entertain_use_component.entertain_use_details_data = s.result
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        },
        outExcel() {
            this.out_excel_heard = [
                {label: '序号',prop: 'order_number'},
                {label: '日期',prop: 'create_time'},
                {label: '费用类型',prop: 'type_name'},
                {label: '金额',prop: 'money'},
                {label: '企业',prop: 'enterprise_name'},
                {label: '部门',prop: 'department_name'},
                {label: '使用人',prop: 'create_staff_name'},
                {label: '来源单据',prop: 'from_type_name'},
                {label: '来源单号',prop: 'with_from_name'},
            ]

            out_excel_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.out_excel_data = s.result
                        this.$refs['out_excel'].outExcel()
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_budget_use_index()
        this.get_all_enterprise()
        this.get_all_staff()
        this.get_enterprise_department()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        BudgetUseSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        PurchaseEditComponent,
        EntertainEditComponent,
        TravelEditComponent,
        ReimbursementEditComponent,
        EntertainUseEditComponent,
        CommonOutPutExcelComponent
    },
    watch: {},
}
</script>

<style lang='less'></style>
<template>
    <el-card class="box-card">
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="费用类型" prop="type">
                <el-select
                    v-model="form.type"
                    placeholder="选择费用类型"
                    class="typec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in AllType"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="企业搜索" prop="enterprise_id">
                <el-select
                    v-model="form.enterprise_id"
                    placeholder="选择企业"
                    class="enterprisec"
                    @change="(enterprise_id) => get_department(enterprise_id)"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="enterprise in AllEnterprise"
                        :key="enterprise.name"
                        :label="enterprise.name"
                        :value="enterprise.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="申请部门" prop="department_id">
                <el-select
                    v-model="form.department_id"
                    placeholder="选择部门"
                    class="departmentc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="department in this.departmentInEnterprise"
                        :key="department.name"
                        :label="department.name"
                        :value="department.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="年份" prop="year">
                <el-select
                    v-model="form.year"
                    placeholder="选择年份"
                    class="yearc"
                    @change="(year) => get_quarter(year)"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="year in AllYear"
                        :key="year.id"
                        :label="year.name"
                        :value="year.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="季度" prop="quarter">
                <el-select
                    v-model="form.quarter"
                    placeholder="选择季度"
                    class="quarterc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="v in AllQuarter"
                        :key="v.id"
                        :label="v.name"
                        :value="v.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="使用人" prop="staff_id">
                <el-select
                    v-model="form.staff_id"
                    placeholder="选择申请人"
                    class="staff_idc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="staff in AllStaff"
                        :key="staff.name"
                        :label="staff.name"
                        :value="staff.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')">重置</el-button>
                <el-button plain @click="$emit('out_excel')" v-if="$_has('导出预算使用明细')">导出</el-button>
            </el-form-item>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加预算</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
import { department_list_request } from '@/network/list.js'

export default {
    name: 'BudgetSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                type: '',
                year: '',
                quarter: '',
                enterprise_id: '',
                department_id: '',
                staff_id: '',
                page: 1
            },
            departmentInEnterprise: [],
            AllQuarter: []
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
            this.departmentInEnterprise = []
            this.AllQuarter = []
        },
        addTeam() {
            this.$emit('addTeam')
        },
        get_department(enterprise_id) {
            this.form.department_id = ''
            this.departmentInEnterprise = []
            if (enterprise_id) {
                department_list_request({ enterprise_id })
                    .then((s) => {
                        if (s.status === 0) {
                            this.departmentInEnterprise = s.result
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err)
                    })
            }
        },
        get_quarter(year) {
            this.form.quarter = ''
            this.AllQuarter = []
            if(year) {
                this.AllQuarter = [{id:1,name:'第一季度'},{id:2,name:'第二季度'},{id:3,name:'第三季度'},{id:4,name:'第四季度'}]
            }
        }
    },
    filters: {},
    props: {
        add_auth: String,
        AllEnterprise: Array,
        AllYear: Array,
        AllType: Array,
        AllStaff: Array
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>